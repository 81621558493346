@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


:root {

    --white: #FFF;
    --black: #222;
    --green: #49f627;
}

.navbar .btn-light {
    background: var(--white) !important;
}

.navbar .btn-light:hover {
    background: var(--white) !important;
}

.navbar .btn-dark {
    background: #777 !important;
}

body.dark-mode {
    font-family: "Courier Prime", serif !important;
}

body.dark-mode h1 {
    font-weight: 900;
}

.banner h1 {
    font-size: 5.55em;
}

.dark-mode {
    background: var(--black) !important;
}

.category-filters label {
    margin-left: 5px;
}


.category-filters input {
    margin-left: 5px;
}


.dark-mode .category-filters label {
    color: var(--white) !important;
}


.dark-mode .navbar {
    background: #111 !important;
}

.dark-mode .navbar-brand {
    color: var(--green) !important;
}

/* Top Cards */

.dark-mode .card {
    background: #111 !important;
}


/* Form */

.dark-mode input,
.dark-mode select {
    color: var(--white) !important;
    background: #111 !important;
}

/* Table */

.dark-mode table {
    background: #111 !important;
}

.dark-mode .table-striped>tbody>tr:nth-of-type(odd)>* {
    color: var(--green) !important;
    background: var(--black) !important;
}


.dark-mode .table>:not(caption)>*>* {
    color: var(--green) !important;
    background-color: #111 !important;
    border-bottom-width: 1px !important;
}

.dark-mode .market-table {
    display: none;
}

.light-mode .market-no-limit-table {
    display: none;
}

/* Utilities */
.dark-mode .txt-green {
    color: var(--green) !important;
}

.dark-mode .txt-light {
    color: var(--white) !important;
}

.dark-mode .custom-pagination button {
    border: 2px solid var(--green) !important;
    color: var(--green) !important;
}

.dark-mode .custom-pagination button:hover {
    background: var(--green);
    color: var(--white) !important;
}

.dark-mode .custom-pagination button.btn-success {
    background: var(--green);
    color: var(--white) !important;
}

@media(max-width: 786px) {
    .labels-div {
        justify-content: start !important;
    }

    body,
    html {
        overflow-x: hidden !important;
    }

    p,
    td,
    a,
    label,
    input,
    select {
        font-size: 13px !important;
    }

    .custom-pagination button {
        font-size: 12px;
    }

    .banner h1 {
        font-size: 2.55em !important;
    }

}

.marquee-container {
    overflow: hidden;
    white-space: nowrap;
    width: 1100px;
    min-height: 5px;
    position: relative;
}


.dark-mode .dark-loading {
    color: var(--white) !important;
}

/* vcj-yuz2fbp4qcv6FQC */


/* About Us */

.dark-mode .about p,
.dark-mode .about ul li {
    color: var(--white) !important;
}




.about .hero {
    padding: 3rem 1rem;
    border-radius: 12px;
}

.about .hero h1 {
    font-size: 3rem;
    font-weight: bold;
}

.about .hero p {
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
}

.about .card {
    border: none;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about .card h5 {
    font-size: 1.25rem;
    font-weight: bold;
}

.about .icon {
    font-size: 1.5rem;
    color: #2575fc;
    margin-right: 10px;
}

.about .filter-list li {
    margin-bottom: 10px;
}

.about .filter-list li span {
    font-weight: bold;
}

.about .footer {
    background: #343a40;
    color: white;
    padding: 2rem 1rem;
    text-align: center;
    border-radius: 12px;
    margin-top: 2rem;
}

.about .footer a {
    color: #49f627;
    text-decoration: none;
}

.about .footer a:hover {
    text-decoration: underline;
}

.dark-mode .about, 
.dark-mode .about p ,
.dark-mode .about ul li,
.dark-mode .about a{
    color: var(--green) !important;
}

.dark-mode .about a {
    font-weight: bold;
}